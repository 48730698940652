.overflow-hidden {
    overflow: hidden;
}
/* .box{
    height:75vh;
    text-transform: uppercase;
    overflow: hidden;
} */

.title-box {
    font-size:17vw;
    font-family:'Neue Montreal Medium';
    font-weight:bold;
    color:rgb(0, 0, 0);
    
}
.resume {
    font-family: 'Neue Montreal Light';
    text-decoration: none;
    font-size:1.25em;
    color:rgb(0, 76, 255);
}
.resume:hover {
    color:rgb(71, 126, 255);
    transition-duration: 0.1s;
}
.space {
    padding:12vmin;
}
@media screen and (min-width: 1500px) {
    .title-box  {
        font-size:25vmin;
   
        
    }
}
@media screen and (max-width: 600px) {
    .title-box  {
        font-size:16vw;
        
        
    }
    .box {
        height:50vh;
    }
}
