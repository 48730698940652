#about-section {
    padding-top:30px;
    /* background-color: rgb(234, 234, 234); */
    font-family:'Neue Montreal';
    min-height:50vh;
    width:auto;
}
.about-description {
    
    font-size:5vmin;
}
.about-description span {
    color:red;
}
.about-title {
    font-size: 3.5vmin;
    padding-top:5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right:10px;
    border-radius:12px;
    border-style:solid;
    border-width: 2.5px;
    color:rgb(146, 81, 0);
}
.image {
    width: 25%;
    border-radius:80px;
    border-style:solid;
    border-color: transparent;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}