.svg-container {
    padding-top:6px;
    position:relative;
    display:inline-block;
}
.caption {
    position: absolute;
    bottom: 1;
    left: 0;
    right: 0;
    color: white;
    padding-top:10px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    font-size:1.2rem;
  }
  
.svg-container:hover .caption {
    opacity: 1;
  }

