#projects-section {
    /* height:100vh; */
    font-family:'Neue Montreal';
    
}


.project-image {
    width: 100%;
    
}

.tab-label {
    font-family:'Neue Montreal Medium';
    
}
.tab-content {
    font-family:'Neue Montreal';
    
}
@media screen and (min-width: 900px) {
    #projects-section {
        height:100vh;
        
    }
    }
