#exp-section {
    padding-top:30px;
    font-family: 'Neue Montreal Medium';
    /* background-color: rgb(234, 234, 234); */
    min-height: 50vh;
    text-transform: uppercase;

}
/* .accordion {

    border-bottom: 1px;
    border-top:0px;
    border-right:0px;
    border-left:0px;
    
    border-style:solid;
   
    
} */
#exp-section a {
    font-size: 8vmin;
    padding-top:5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right:10px;
    color:rgb(255, 255, 255);
}



.accordion span {
    font-size:4vmin;
   
    
}
.accordion p {
    font-family:'Neue Montreal Italic';
    
}
.svg {
    padding-top:10px;
}