#footer {
    color:black;
    font-family:'Neue Montreal';
    text-transform:uppercase;
    padding:10px;
    background-color: rgb(234, 234, 234);
    height: 70vh;
    text-transform: uppercase;
}
.arrow {
    color:black;
    transition-duration: 0.2s;
}
.arrow:hover {
    color:white;
}
.footer-header {
    font-size:17vw;
    font-family:'Neue Montreal Medium';
    font-weight:bold;
    color:rgb(250, 250, 250);
    
}
.reach {
    font-family:'Neue Montreal Light Italic';
    font-size:1.5em;
}
#footer a {
    color:black;
    transition: 0.20s;
}
#footer a:hover {
    color:white;
}
@media screen and (min-width: 1500px) {
    .footer-header {
        font-size:25vmin;
        
   
        
    }
}
@media screen and (max-width: 600px) {
    .footer-header {
        font-size:16vw;
        
   
        
    }
}