@font-face {
    font-family: 'Neue Montreal';
    src: url('../fonts/NeueMontreal-Regular.otf')
}
@font-face {
    font-family: 'Neue Montreal Bold';
    src: url('../fonts/NeueMontreal-Bold.otf')
}
@font-face {
    font-family: 'Neue Montreal Bold Italic';
    src: url('../fonts/NeueMontreal-BoldItalic.otf')
}
@font-face {
    font-family: 'Neue Montreal Italic';
    src: url('../fonts/NeueMontreal-Italic.otf')
}
@font-face {
    font-family: 'Neue Montreal Light';
    src: url('../fonts/NeueMontreal-Light.otf')
}
@font-face {
    font-family: 'Neue Montreal Light Italic';
    src: url('../fonts/NeueMontreal-LightItalic.otf')
}
@font-face {
    font-family: 'Neue Montreal Medium';
    src: url('../fonts/NeueMontreal-Medium.otf')
}
@font-face {
    font-family: 'Neue Montreal Medium Italic';
    src: url('../fonts/NeueMontreal-MediumItalic.otf')
}
.main-container {
    background-color: rgb(255, 255, 255);
    color:rgb(0, 0, 0);
    
}

.navbar-font {
    font-family:"Neue Montreal Medium";
    font-size: 1.5em;
}
.main-font {
    font-family: "Neue Montreal Bold";
    font-size: 17vmin;
    /* font-weight:1000; */
}
.main-description-font {
    font-family: "Neue Montreal";
    font-size: 20px;
}

.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
.social-icon{
    padding:100px;
}
/* section {
    height: 100vh;
} */

.directory-font  {
    font-family:"Neue Montreal Medium";
   list-style: none;
   font-size:30px;
}
hr.thick-line {
    border-top: 3px solid #272727;
}
hr.thin-line {
    border-top:1.5px solid #272727;
}
.directory a {
    color: white !important;
    text-decoration: none;
}
.directory-container {
    height:100vh;
}

.directory-item {
    color:white;
    background-image: url('../images/oldtech.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position-y: center;
    height:100vw;
    background-origin:padding-box;
    transition-duration:0.5s, 0.2s;
    text-decoration:none;
    
    
}
.directory-item span {
    border-style: solid;
    border-top: none;
    border-right: none;
    border-left: none;
}

.directory-item:hover {
    padding-bottom: 20%;

    
} 
#tech {
    background-image: url('../images/desk.jpg')
}

#projects {
    background-image: url('../images/crt.gif')
}
#exp {
    background-image: url('../images/90s.jpg')
}


@media screen and (min-width: 900px) {
.directory-item {
   
    background-position-x: center;
    width:25vw;
    height: auto;
    
    
}
.directory-item:hover {
    padding-right: 20%;

    
}
.directory-item span {
    font-size: 20vmin;
}
}

