.section-label-number {
    font-size: 100px;
    text-transform: uppercase;
    font-family: 'Neue Montreal Medium';
    border-right: solid 1.5px;
    padding-right:20px;
    padding-top:-100px;
    color:rgb(0, 0, 0);
}
.section-label {
    font-size: 3vmin;
    text-transform: uppercase;
    font-family: 'Neue Montreal Medium';
    color: rgb(71, 71, 71);
}
@media screen and (max-width: 900px) {
    .section-label-number {
        font-size: 5vmin;
        text-transform: uppercase;
        font-family: 'Neue Montreal Bold';
        border-right: solid 1.5px;
        padding-right:20px;
        padding-top:-100px;
        color:rgb(255, 255, 255);
    }
    .section-label {
        font-size: 5vmin;
        text-transform: uppercase;
        font-family: 'Neue Montreal';
    }
}
